import React, { useContext, useEffect, useState } from 'react'
import { AppState } from '../App'
import Header from './Header';
import Register from './Register';
import UserInfo from './UserInfo';
import Navigation from './Navigation';
import CopyToClipboard from 'react-copy-to-clipboard';
import { message } from 'antd';
import { TailSpin } from 'react-loader-spinner';
import { CopyFilled, LikeFilled } from '@ant-design/icons';
import { ethers } from 'ethers';
import { useTimer } from 'react-timer-hook';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days
  } = useTimer({ expiryTimestamp});

  return <p><span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
}

const Dashboard = () => {
  const useAppState = useContext(AppState);
  const [user, setUser] = useState({
    level: 0,
    income: 0,
    matrixTeam: 0,
    directTeam: 0,
    royaltyIncome: 0,
    referralIncome: 0,
    levelIncome: 0
  })
  const [lvl, setLvl] = useState({
    lvl: 0,
    total: 0
  })
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({
    "0": false,
    "1": false,
    "2": false,
    "3": false,
    "4": false,
    "5": false,
    "6": false,
    "7": false,
    "8": false,
    "9": false,
    "10": false,
  })
  const [income, setIncome] = useState([{
    from: "",
    time: 0,
    amount: 0,
    layer: 0,
    level: 0
  }])
  const [packageWid, setPackageWid] = useState(0);
  const [showRoyalty, setShowRoyalty] = useState(false);
  const [roayltyTime, setRoyaltyTime] = useState(0);
  const [royalty, setRoyalty] = useState([0,0,0]);
  const [royaltyUsers, setRoyaltyUsers] = useState([[0], [0], [0]]);
  const [totalRoyaltyUsers, setTotalRoyaltyUsers] = useState([0, 0, 0]);
  const [lvlIncome, setLvlIncome] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  useEffect(() => {
    async function getData() {
      let _user = await useAppState.staticContract.userInfo(useAppState.id);
      setUser({
        level: Number(_user.level),
        income: useAppState.convert(_user.totalIncome),
        matrixTeam: Number(_user.totalMatrixTeam),
        directTeam: Number(_user.directTeam),
        royaltyIncome: useAppState.convert(_user.royaltyIncome,),
        referralIncome: useAppState.convert(_user.referralIncome,),
        levelIncome: useAppState.convert(_user.levelIncome)
      })

      setPackageWid(parseInt(((Number(user.level) * 100) / 12)))

      setSelected({
        "0": false,
        "1": false,
        "2": false,
        "3": false,
        "4": false,
        "5": false,
        "6": false,
        "7": false,
        "8": false,
        "9": false,
        "10": false,
      });

      setLvl({
        lvl: 0,
        total: 0
      })

      let _royaltyTime = await useAppState.staticContract.getRoyaltyTime();
      setRoyaltyTime(Number(_royaltyTime));
      setShowRoyalty(true);

      let _royalty = await useAppState.staticContract.royalty(0);
      let _royalty1 = await useAppState.staticContract.royalty(1);
      let _royalty2 = await useAppState.staticContract.royalty(2);
      setRoyalty([useAppState.convert(_royalty), useAppState.convert(_royalty1), useAppState.convert(_royalty2)]);

      setLvlIncome([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      let _lvlIn = [];
      let _lvlIncome = await useAppState.staticContract.getLevelIncome(useAppState.id);
      _lvlIncome.forEach((e) => {
        _lvlIn.push(useAppState.convert(e));
      })
      setLvlIncome(_lvlIn);
      
      setIncome([]);
      let _income = await useAppState.staticContract.getIncome(useAppState.id);
      if(_income.length > 0) {
        for(let i=_income.length-1; i>=0; i--) {
          setIncome((prev) => [...prev, {
            from: Number(_income[i].id),
            time: Number(_income[i].time) * 1000,
            amount: useAppState.convert(_income[i].amount),
            layer: Number(_income[i].layer),
            level: useAppState.levels.indexOf(Number(useAppState.convert(_income[i].amount))) + 1
          }])
        }
      }

      setRoyaltyUsers([[], [], []]);
      let royalty = [[], [], []];
      let _royaltyUsers = await useAppState.staticContract.getRoyaltyUsers(0);
      let _royaltyUsers1 = await useAppState.staticContract.getRoyaltyUsers(1);
      let _royaltyUsers2 = await useAppState.staticContract.getRoyaltyUsers(2);
      setTotalRoyaltyUsers([_royaltyUsers.length, _royaltyUsers1.length, _royaltyUsers2.length]);
      // for(let i=0; i<_royaltyUsers.length; i++) {
      //   royalty[0].push(Number(_royaltyUsers[i]));
      // }
      // for(let i=0; i<_royaltyUsers1.length; i++) {
      //   royalty[1].push(Number(_royaltyUsers1[i]));
      // }
      // for(let i=0; i<_royaltyUsers2.length; i++) {
      //   royalty[2].push(Number(_royaltyUsers2[i]));
      // }
      // setRoyaltyUsers(royalty);
    }
    getData();
  },[useAppState.walletAddress, useAppState.change, useAppState.id])

  useEffect(() => {
    async function getData() {
      if(user.level > 0 && !useAppState.shown) {
        let _act = await useAppState.staticContract.getRecentActivities(5);
        NotificationManager.removeAll();
        for(let i=0; i<_act.length; i++) {
          setTimeout(() => {
            NotificationManager.info(`ID: ${Number(_act[i].id)} just got upgraded to ${useAppState.ranks[Number(_act[i].level)-1]}`, null, 2500)
            if(i == _act.length - 1) useAppState.setShown(true);
          }, i * 3300);
        }
      }
    }
    getData();
  },[user.level])

  const upgrade = async () => {
    setLoading(true);
    try {
      let contract = await useAppState.getContract();
      let tx = await contract.upgrade(useAppState.id, lvl.lvl,{
        value: ethers.parseEther((lvl.total.toFixed(5)).toString())
      });
      await tx.wait();
      setLvl({
        lvl: 0,
        total: 0
      })
      useAppState.setChange(useAppState.change + 1);
      message.success("Sucessfully Upgraded");
    } catch (error) {
      message.error(error.reason);
      console.log(error.message)
    }
    setLoading(false);
  }

  const select = (amt, i) => {
    if(selected[i - 1] == true || i == user.level + 1) {
      setLvl((prev) => ({lvl: prev.lvl + 1, total: prev.total + amt})); 
      setSelected((prev) => ({...prev, [i]: true}));
    }
  }
  
  const deSelect = (amt, i) => {
    if(selected[i + 1] == false || i == useAppState.levels.length - 1) {
      setLvl((prev) => ({lvl: prev.lvl - 1, total: prev.total - amt})); 
      setSelected((prev) => ({...prev, [i]: false}));
    }
  } 

  return (
    <>
      <img
        src="bgimg.png"
        className="fixed hidden md:block right-0 top-0 z-0 opacity-100 w-full h-full"
      />
      <img
        src="bgmobimg.png"
        className="fixed w-full left-0 md:top-0 block md:hidden top-0 z-0 opacity-100"
      />

      <NotificationContainer />

      <div className="pb-4 text-black dark:text-white transition-colors duration-1000 min-h-screen relative">
        <Header />

        <div className="flex justify-center w-full px-4 mt-6">
          <div className="w-full md:w-3/4">
            <Navigation />
          </div>
        </div>

        <Register />

        <div className="flex justify-center px-4 md:p-0 mt-6">
          <div className="md:w-3/4 w-full">
            <UserInfo />
          </div>
        </div>

        <div className="flex flex-col items-center px-4 md:p-0 mt-6">
            <h1 className="md:w-3/4 w-full flex justify-start overflow-x-auto font-bold text-2xl text-green-500">Packages</h1>
          <div className="md:w-3/4 w-full flex justify-start gap-x-4 overflow-x-auto">
            {useAppState.levels.map((e, i) => {
              let amt = e + (e * useAppState.percent[i]) / 100;
              return (
                <div
                  onClick={() => {
                    selected[i] ? deSelect(amt, i) : select(amt, i);
                  }}
                  className={`${i > 0 ? "" : "hidden"} ${
                    user.level >= i ? "hidden" : null
                  } flex-col cursor-pointer blue_blur p-4 mt-4 font-bold text-black dark:text-white min-w-32 min-h-32 flex justify-center items-center rounded-full bg-white dark:bg-[#1e2026] shadow-lg`}
                >
                  <p className="text-gray-800 dark:text-[#FFE900] mt-1">
                    {amt.toFixed(5)}
                  </p>
                  <p className="bg-gray-200 rounded-full text-gray-800 min-w-8 flex justify-center items-center text-sm p-1 mt-1">
                    {useAppState.ranks[i - 1]}{" "}
                    {selected[i] ? <span className="ml-1">✅</span> : null}
                  </p>
                  <img src="bnb.png" className="h-8 mt-1" />
                </div>
              );
            })}
          </div>
        </div>

        {lvl.lvl > 0 ? (
          <div className="flex justify-center px-4 md:p-0 mt-8">
            <div className="md:w-3/4 w-full">
              <button
                onClick={upgrade}
                className="w-full flex justify-center items-center cursor-pointer bg-green-500 rounded-sm text-sm font-bold py-3 px-3 text-white"
              >
                {loading ? (
                  <TailSpin height={15} color="white" />
                ) : (
                  <span>Upgrade {lvl.total.toFixed(5)} BNB</span>
                )}
              </button>
            </div>
          </div>
        ) : null}

        <div className="flex justify-center px-4 md:p-0 mt-6">
          <div className="md:w-3/4 w-full">
            <div className="w-full flex justify-between items-center bg-whtie dark:bg-[#1e2026] dark:bg-opacity-40 rounded-lg shadow-lg p-4">
              <div className="overflow-x-auto overflow-y-hidden">
                <h1 className="text-lg font-bold mb-4 text-white">Referral Link</h1>
                <div className='flex justify-between items-center text-2xl'>
                  <CopyToClipboard
                    onCopy={() => message.success("Copied!")}
                    text={`https://rideop.pro/dashboard?ref=${useAppState.id}`}
                  >
                    <span className="max-w-full cursor-pointer text-sm font-medium overflow-x-auto bg-blue-500 rounded-sm px-3 py-2 text-white whitespace-nowrap">
                      {`https://rideop.pro/dashboard?ref=${useAppState.id}`}{" "}
                    </span>
                  </CopyToClipboard>
                  <CopyToClipboard
                      onCopy={() => message.success("Copied!")}
                      text={`https://rideop.pro/dashboard?ref=${useAppState.id}`}
                    >
                    <CopyFilled className="ml-2 cursor-pointer" />
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Royalty */}
        <div className="flex justify-center px-4 md:p-0 mt-6">
          <div className="md:w-3/4 w-full">
            <div className="w-full flex flex-col justify-center items-center bg-whtie dark:bg-[#1e2026] dark:bg-opacity-40 rounded-lg shadow-lg p-4">
              <p className="border-2 border-[rgba(124,240,89,0.16)] bg-[rgba(124,240,89,0.14)] py-3 px-6 font-bold text-xl flex flex-col justify-center">
                <span className='w-full text-center'>Daily Royalty</span> 
                <span className='w-full text-center'>Countdown</span>
              </p>
              {showRoyalty ? (
                <p className="text-2xl mt-2 font-mono px-3 rounded-sm text-white font-bold">
                  {<MyTimer expiryTimestamp={roayltyTime * 1000} />}
                </p>
              ) : null}
            </div>
          </div>
        </div>

        <div className="flex justify-center px-4 md:p-0 mt-4">
          <div className="md:w-3/4 w-full">
            <div className="flex justify-start items-center overflow-x-auto w-full">
              <div className="flex justify-center w-full">
                <div
                  className={`overflow-auto max-h-40 flex justify-between items-start w-full px-2 rounded-sm`}
                >
                  <div className="whitespace-nowrap md:ml-0">
                    <p className="flex flex-col justify-center items-center whitespace-nowrap py-1 px-3 border-2 border-[rgba(240,194,89,.16)] text-white bg-[rgba(240,194,89,.14)] font-medium">
                      <span>Icon <span className='bg-white px-3 text-xs font-bold text-gray-800 ml-1'>{totalRoyaltyUsers[0]}</span></span> <span className='font-bold'>{royalty[0]} <span className='text-[#f0c259]'>BNB</span></span>
                    </p>
                    {royaltyUsers[0].map((e, i) => {
                      return (
                        <p
                          key={i}
                          className="whitespace-nowrap border-2 border-[rgba(124,240,89,0.16)] text-white bg-[rgba(124,240,89,0.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                        >
                          {e}
                        </p>
                      );
                    })}
                  </div>

                  <div className="whitespace-nowrap ml-4 md:ml-0">
                    <p className="flex flex-col justify-center items-center whitespace-nowrap py-1 px-3 border-2 border-[rgba(240,194,89,.16)] text-white bg-[rgba(240,194,89,.14)] font-medium">
                      <span>Legend <span className='bg-white px-3 text-xs font-bold text-gray-800 ml-1'>{totalRoyaltyUsers[1]}</span></span> <span className='font-bold'>{royalty[1]} <span className='text-[#f0c259]'>BNB</span></span>
                    </p>
                    {royaltyUsers[1].map((e, i) => {
                      return (
                        <p
                          key={i}
                          className="whitespace-nowrap border-2 border-[rgba(124,240,89,0.16)] text-white bg-[rgba(124,240,89,0.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                        >
                          {e}
                        </p>
                      );
                    })}
                  </div>

                  <div className="whitespace-nowrap ml-4 md:ml-0">
                    <p className="flex flex-col justify-center items-center whitespace-nowrap py-1 px-3 border-2 border-[rgba(240,194,89,.16)] text-white bg-[rgba(240,194,89,.14)] font-medium">
                      <span>Emperor <span className='bg-white px-3 text-xs font-bold text-gray-800 ml-1'>{totalRoyaltyUsers[2]}</span></span> <span className='font-bold'>{royalty[2]} <span className='text-[#f0c259]'>BNB</span></span>
                    </p>
                    {royaltyUsers[2].map((e, i) => {
                      return (
                        <p
                          key={i}
                          className="whitespace-nowrap border-2 border-[rgba(124,240,89,0.16)] text-white bg-[rgba(124,240,89,0.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                        >
                          {e}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center px-4 md:p-0 mt-8">
          <div className="md:w-3/4 w-full">
            <div className="flex flex-col md:flex-row justify-between text-white">
              <div className="flex justify-between w-full md:w-1/2 bg-[#1e2026] bg-opacity-40 p-4 shadow-lg rounded-sm font-bold text-lg">
                <div>
                  <p className="text-lg font-bold text-[#FFE900]">
                    Total Income
                  </p>
                  <p className='text-xl font-bold'>{user.income} BNB</p>
                </div>
                <img src="bnb.png" className="h-12" />
              </div>
              <div className="flex justify-between ml-0 md:ml-4 mt-4 md:mt-0 w-full md:w-1/2 bg-[#1e2026] bg-opacity-40 p-4 shadow-lg rounded-sm font-bold text-lg">
                <div>
                  <p className="text-lg font-bold text-[#FFE900]">
                    Referral Income
                  </p>
                  <p className='text-xl font-bold'>{user.referralIncome} BNB</p>
                </div>
                <img src="bnb.png" className="h-12" />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center px-4 md:p-0 mt-4">
          <div className="md:w-3/4 w-full">
            <div className="flex flex-col md:flex-row justify-between text-white">
              <div className="flex justify-between w-full md:w-1/2 bg-[#1e2026] bg-opacity-40 p-4 shadow-lg rounded-sm font-bold text-lg">
                <div>
                  <p className="text-lg font-bold text-[#FFE900]">
                    Level Income
                  </p>
                  <p className='text-xl font-bold'>{user.levelIncome} BNB</p>
                </div>
                <img src="bnb.png" className="h-12" />
              </div>
              <div className="flex justify-between ml-0 md:ml-4 mt-4 md:mt-0 w-full md:w-1/2 bg-[#1e2026] bg-opacity-40 p-4 shadow-lg rounded-sm font-bold text-lg">
                <div>
                  <p className="text-lg font-bold text-[#FFE900]">
                    Royalty Income
                  </p>
                  <p className='text-xl font-bold'>{user.royaltyIncome} BNB</p>
                </div>
                <img src="bnb.png" className="h-12" />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center px-4 md:p-0 mt-4">
          <div className="md:w-3/4 w-full">
            <div className="flex flex-col md:flex-row justify-between text-white">
              <div className="flex justify-between w-full md:w-1/2 bg-[#1e2026] bg-opacity-40 p-4 shadow-lg rounded-sm font-bold text-lg">
                <div>
                  <p className="text-lg font-bold text-[#FFE900]">
                    My Community Size
                  </p>
                  <p className='text-xl font-bold'>{user.matrixTeam}</p>
                </div>
                <img src="matrix.png" className="h-12" />
              </div>
              <div className="flex justify-between ml-0 md:ml-4 mt-4 md:mt-0 w-full md:w-1/2 bg-[#1e2026] bg-opacity-40 p-4 shadow-lg rounded-sm font-bold text-lg">
                <div>
                  <p className="text-lg font-bold text-[#FFE900]">
                    Direct Referrals
                  </p>
                  <p className='text-xl font-bold'>{user.directTeam}</p>
                </div>
                <img src="leader.png" className="h-12" />
              </div>
            </div>
          </div>
        </div>

        {/* Lvl Income */}
        <div className="flex justify-center px-4 md:p-0 mt-8">
            <div className="md:w-3/4 w-full">
              <h1 className="font-bold text-2xl px-2">
                Rank <span className="text-green-500">Income</span>
              </h1>
              <div className="overflow-auto flex justify-between w-full mt-2 p-2 rounded-sm">
                <div className="whitespace-nowrap ml-0 md:ml-0 w-1/2">
                  <p className="text-center text-lime-500 whitespace-nowrap border-2 border-[rgba(240,194,89,.16)] bg-[rgba(240,194,89,.14)] rounded-lg font-semibold p-1 mt-2 px-4">
                    Rank
                  </p>
                  {useAppState.ranks.map((e, i) => {
                    return (
                      <p
                        key={i}
                        className="text-center whitespace-nowrap border-2 border-[rgba(89,222,240,0.16)] text-white bg-[rgba(89,222,240,0.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                      >
                        {e}
                      </p>
                    );
                  })}
                </div>

                <div className="whitespace-nowrap ml-4 w-1/2">
                  <p className="text-center text-lime-500 whitespace-nowrap border-2 border-[rgba(240,194,89,.16)] bg-[rgba(240,194,89,.14)] rounded-lg font-semibold p-1 mt-2 px-4">
                    Amount
                  </p>
                  {lvlIncome.map((e, i) => {
                    return (
                      <p
                        key={i}
                        className="text-center whitespace-nowrap border-2 border-[rgba(89,222,240,0.16)] text-white bg-[rgba(89,222,240,0.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                      >
                        {e} <span className="text-[#FFE900]">BNB</span>
                      </p>
                    );
                  })}
                </div>
            </div>
          </div>
        </div>

        {income.length > 0 ? (
          <div className="flex justify-center px-4 md:p-0 mt-8">
            <div className="md:w-3/4 w-full">
              <h1 className="font-bold text-2xl px-2">
                Recent <span className="text-green-500">Income</span>
              </h1>
              <div className="overflow-auto h-52 flex justify-between w-full mt-2 p-2 rounded-sm">
                <div className="whitespace-nowrap ml-0 md:ml-0">
                  <p className="text-center text-[#f0c259] whitespace-nowrap border-2 border-[rgba(124,240,89,0.16)] bg-[rgba(124,240,89,0.14)] rounded-lg font-semibold p-1 mt-2 px-4">
                    From
                  </p>
                  {income.map((e, i) => {
                    return (
                      <p
                        key={i}
                        className="text-center whitespace-nowrap border-2 border-[rgba(240,194,89,.16)] text-white bg-[rgba(240,194,89,.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                      >
                        {e.from}
                      </p>
                    );
                  })}
                </div>

                <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="text-center text-[#f0c259] whitespace-nowrap border-2 border-[rgba(124,240,89,0.16)] bg-[rgba(124,240,89,0.14)] rounded-lg font-semibold p-1 mt-2 px-4">
                    Amount
                  </p>
                  {income.map((e, i) => {
                    return (
                      <p
                        key={i}
                        className="text-center whitespace-nowrap border-2 border-[rgba(240,194,89,.16)] text-white bg-[rgba(240,194,89,.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                      >
                        {e.amount} <span className="text-[#f0c259]">BNB</span>
                      </p>
                    );
                  })}
                </div>

                <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="text-center text-[#f0c259] whitespace-nowrap border-2 border-[rgba(124,240,89,0.16)] bg-[rgba(124,240,89,0.14)] rounded-lg font-semibold p-1 mt-2 px-4">
                    Rank Level
                  </p>
                  {income.map((e, i) => {
                    return (
                      <p
                        key={i}
                        className="text-center whitespace-nowrap border-2 border-[rgba(240,194,89,.16)] text-white bg-[rgba(240,194,89,.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                      >
                        {e.level == 0
                          ? "Royalty"
                          : `${useAppState.ranks[e.level - 2]}`}
                      </p>
                    );
                  })}
                </div>

                <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="text-center text-[#f0c259] whitespace-nowrap border-2 border-[rgba(124,240,89,0.16)] bg-[rgba(124,240,89,0.14)] rounded-lg font-semibold p-1 mt-2 px-4">
                    Layer
                  </p>
                  {income.map((e, i) => {
                    return (
                      <p
                        key={i}
                        className="text-center whitespace-nowrap border-2 border-[rgba(240,194,89,.16)] text-white bg-[rgba(240,194,89,.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                      >
                        {e.layer == 0 ? "Royalty" : `Layer ${e.layer}`}
                      </p>
                    );
                  })}
                </div>

                <div className="whitespace-nowrap ml-4 md:ml-0">
                <p className="text-center text-[#f0c259] whitespace-nowrap border-2 border-[rgba(124,240,89,0.16)] bg-[rgba(124,240,89,0.14)] rounded-lg font-semibold p-1 mt-2 px-4">
                    Time
                  </p>
                  {income.map((e, i) => {
                    return (
                      <p
                        key={i}
                        className="text-center whitespace-nowrap border-2 border-[rgba(240,194,89,.16)] text-white bg-[rgba(240,194,89,.14)] rounded-lg font-semibold p-1 mt-2 px-4"
                      >
                        {useAppState.getUTCTime(e.time)}
                      </p>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default Dashboard